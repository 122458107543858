import React from 'react'
import Styles from './Style.module.css'
const Content = ({title, stitle, content}) => {
  return (
    <div id='redirect' className={Styles.flex}>
      <div className={Styles.width}>
        <span className={Styles.headtext}>
        <h3 className='fs-md-2 fs-1'>{title}</h3>
        <h3 className='fs-md-2 fs-1'>{stitle}</h3>
        <p className='py-2 fw-light fontSize'>{content}</p>
        </span>
        <div className={Styles.form}>
          <p className='fs2'>REQUEST MORE INFORMATION</p>
          <input className={Styles.input} type="text" placeholder='First Name' />
          <input className={Styles.input} type="text" placeholder='Last Name' />
          <input className={Styles.input} type="email" placeholder='Email' />
          <input className={Styles.input} type="number" placeholder='Phone number' />
          <br /><br />
          <button className={Styles.btn}>Submit</button>
        </div>
        </div>
      </div>
  )
}

export default Content
