import React from "react";
import Styles from "./Style.module.css";
import Content from "./Content";
const Second = () => {
  const title = "Award in International";
  const stitle = "Wealth Management";
  const content =
    "A Joint Certification offered by leading global education brand Moody's Analytics Global Education (MAGE) and one of India's best institutes for finance, PGP Academy";
  return (
    <div className={Styles.page}>
      <Content title={title} stitle={stitle} content={content} />
      <div className={Styles.padding}>
        <h5 className={Styles.head}>
          What is the Award in International Wealth Management?
        </h5>
        <p className="py-2 fontSize">
          Award in International Wealth Management is a foundational wealth
          management program offered jointly by Moody's Analytics and PGP
          Academy, to equip emerging wealth professionals with the specialized
          knowledge they need to cater to their clients’ evolving needs in both
          a local and global context. The program is tuned towards the Indian
          financial services professionals, to assist them take the leap to
          comprehensive wealth management - the next level of evolution in
          services from financial planning or goal planning.
        </p>
      </div>
      <div className={Styles.background}>
        <div className={Styles.padding}>
          <h5 className={Styles.head} style={{ textAlign: "center" }}>
            Benefits of the Award in International Wealth Management
          </h5>
          <div className="d-sm-flex d-block pt-5 justify-content-space-between">
            <div className="text-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2618/2618529.png"
                alt="logo"
                className={Styles.icons}
              />
              <p className="text-dark p-2" style={{ fontSize: "14px" }}>
                Widely recognized by the Banking and Financial Services Industry
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/591/591777.png"
                alt="logo"
                className={Styles.icons}
              />
              <p className="text-dark p-2" style={{ fontSize: "14px" }}>
                Gain international insights to move ahead as a Wealth Management
                professional
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://cdn-icons-png.flaticon.com/128/3315/3315001.png"
                alt="logo"
                className={Styles.icons}
              />
              <p className="text-dark p-2" style={{ fontSize: "14px" }}>
                Stand out among your peers with an International Award
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className={`${Styles.padding} ${Styles.program}`}>
        <div className={Styles.contentBox}>
          <p style={{ fontSize: "16px", color: "black", paddingBottom: '5px'}}>
            Award in International Wealth Management Fees
          </p>
          {/* <p className="d-flex" style={{ fontSize: "10px" }}>
            3 days
          </p> */}
          <hr className={Styles.hr} />
          <p className="fs3" style={{color: 'black'}}>
          The fees (all-inclusive) includes:
          </p>
          <ul className="fs3">
            <li>Registration fees</li>
            <li>Access to all live and recorded training sessions</li>
            <li>Assessment Fees</li>
          </ul>
          <span className="d-flex" style={{ color: "blue" }}>
            <small className="pt-3" style={{ fontSize: "10px" }}>
              Rs.
            </small>
            <h4 className="pt-1 fw-bolder px-1">24000</h4>
          </span>
          <div className={Styles.btns}>
            <button onClick={() =>window.location.replace('https://forms.eduqfix.com/aiwmof/add')} className={Styles.btn}>enrol now</button>
            <button className={Styles.otherbtn}>get in touch</button>
          </div>
        </div>

        <div>
          <p
            className="text-dark px-sm-3 px-1 pt-sm-0 fs2"
          >
            Program Features and Curriculum:
          </p>
          <ul className="fs3">
            <li>
            The Award in International Wealth Management is a 20-hour, live, online training program. 
            </li>
            <li>
            Top National & International Coaches, including those from Moody's Analytics
            </li>
            <li>Access to various practical tools and resources</li>
            <li>Access live sessions on phones, tablets, desktops or laptops</li>
            <li>Session-end Assignments to confirm knowledge transfer</li>
            <li>Access to recordings of all sessions</li>
          </ul>
        </div>
      </div>
      <div className={Styles.padding}>
        <p
          style={{ fontSize: "18px" }}
          className="text-dark px-sm-5 px-0 my-4 fw-bold"
        >
          Curriculum
        </p>
        <hr className={Styles.hr} />
        <div className={Styles.program}>
          <ul className="fs3 px-sm-3 py-1">
            <li>Behavioral Finance</li>
            <li>Global Wealth ManagementTrends</li>
            <li>Practice Management</li>
            <li>Business Succession Planning</li>
            <li>Introduction to FinTech</li>
          </ul>
          <ul className="fs3 px-sm-3 py-1">
            <li>Alternative Investments, ReiTS, PMS, and AIF</li>
            <li>Estate Planning</li>
            <li>Personal Tax Planning</li>
            <li>ESG Investing</li>
            <li>Economics of Global Trade</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>Time Value of Money</li>
            <li>Basic Personal Financial Report</li>
            <li>Risk Profiling of Clients</li>
            <li>Retirement Planning</li>
            <li>House Loans</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>Protecting Children's Goals</li>
            <li>Life Insurance Planning</li>
            <li>Performance Evaluation</li>
            <li>MF scheme selection</li>
            <li>Comprehensive Wealth Management Process</li>
          </ul>
        </div>

         <p className="pt-4"></p>
        {/*<hr className={Styles.hr} />
        <div className="d-block d-sm-flex">
          <span className="px-sm-2 px-3" style={{ minWidth: '270px' }}>
            <p style={{ fontSize: "14px", color: "black" }} className="px-2" >
            About Moody's Analytics
            </p>
            <img src="/Moodys Logo.jpg" className={Styles.png2} />
          </span>
          <span>
            <p className="py-1 w-100 px-2 fs3">
            Moody's Analytics provides financial intelligence and analytical tools to help business leaders make better, faster
             decisions. Our deep risk expertise, expansive information resources, and innovative application of technology help our
              clients confidently navigate an evolving marketplace. We are known for our industry-leading and award-winning solutions,
               made up of research, data, software, and professional services, assembled to deliver a seamless customer experience. We
                create confidence in thousands of organizations worldwide, with our commitment to excellence, open mindset approach, and
                 focus on meeting customer needs.
            </p>
             <span className="px-">
               <button className={Styles.btn}>learn more</button> 
            </span> 
          </span>
        </div>

        <p className="pt-4"></p>
        <hr className={Styles.hr} />
        <div className="d-block d-sm-flex pb-5">
          <span className="px-sm-2 px-3" style={{ minWidth: "270px" }}>
            <p style={{ fontSize: "14px", color: "black" }} className="px-2" >
              About PGP Acadamy
            </p>
            <img src="/PGP WHITE LOGO 2.png" className={Styles.png} />
          </span>
          <span>
            <p className="py-1 w-100 px-2 fs3">
            PGP Academy is a premier institute for financial planning and wealth management and has been consistently delivering the
             best education in the Banking and Financial Services segment since its inception. PGP is focused adding value to experienced
              and aspiring BFSI professionals through coaching, training, and certification in line with the seismic changes taking place
               in the Banking and Financial Services Industry today. PGP Academy has been a partner to some of the largest and most sought
               -after educational bodies in the world and is currently working with various corporates as well as universities and colleges
                on multiple projects.
            </p>
               <span className="px-">
                <button className={Styles.btn}>learn more</button> 
            </span> 
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Second;
