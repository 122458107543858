import React from 'react'
import Styles from './Style.module.css';
import Content from './Content';
import {Link} from 'react-router-dom';
const Third = () => {
  const title = 'Certified International'
  const stitle = 'Wealth Manager (CIWM)'
  const content = 'The leading credential for wealth managers'
  return (
    <div className={Styles.page}>
      <Content title={title} stitle={stitle} content={content} />
      <div className={Styles.padding}>
        
        <p className="p-4 p-sm-3"></p>
        <h5 className={Styles.head}>What is the Certified International Wealth Manager (CIWM) designation?</h5>
      <p className='py-2 fontSize'>The Certified International Wealth Manager (CIWM) designation is recognized worldwide as a top credential for wealth 
      management. It ensures that financial professionals have the unique knowledge and skills to address the complex needs of high-net-worth clients.
      </p>
      <p className='py-2 fontSize'>Co-granted by Moody's Analytics and the Association of International Wealth Management, the CIWM has international 
      recognition and portability—creating career opportunities for you anywhere in the world.</p>
      <p className='py-2 fontSize'>The Association of International Wealth Management is a professional association for wealth managers worldwide. A not-for-profit based in Switzerland, the AIWM promotes and strengthens global education in private 
        banking and wealth management. It was established to:</p>
        <ul className='fs3'>
          <li>Identify and address the needs of high-net-worth clients.</li>
          <li>Respond to the growing demand for sophisticated products and professional client service.</li>
          <li>Navigate the increasing regulation and general complexities of global financial markets. </li>
        </ul>
      <p className="py-1 fontSize">With the launch of the CIWM designation in 2004, the AIWM has set a globally recognized standard for wealth management professionals. </p>
      {/* <br />
      <h5 className={Styles.head}>What skills will you develop?</h5>
      <ul className='fs3'>
        <li>Indian financial landscape</li>
        <li>Client discovery</li>
        <li>Investment management</li>
        <li>Insurance planning</li>
        <li>Financial planning</li>
        <li>Retirement planning</li>
        <li>Estate Planning</li>
      </ul>
      <br />
      <h5 className={Styles.head}>What is the course syllabus?</h5>
      <div className={Styles.headline}>- Accumulating Wealth for Clients Course</div>
      <br />
      <div className={Styles.padding}>
      <a className={Styles.headline} style={{display: 'block'}} href='#redirect'>- Lesson 1 - Overview of the Financial Landscape, Markets, Economy, and Regulations in India</a>
      <p className="py-2 fontSize">India has a well-diversified financial sector. In this lesson we cover the various components of this sector which comprises commercial banks, insurance
companies, mutual funds, pension funds, <b>non-banking financial companies (NBFC)</b>, co-operatives, and other smaller financial entities which provide
nearly 20 per cent of India's GDP.</p>
      <br />
      <p>Topic Covered:</p>
      <ul className='fs3'>
        <li>The Indian Financial System</li>
        <li>Regulatory Framework</li>
        <li>Increasing Digitization</li>
        <li>Growth in the financial services industry</li>
      </ul>
      <div className={Styles.headline}>+ Lesson 2 - Wealth Management Today</div>
      <br />
      <div className={Styles.headline}>+ Lesson 3 - Understanding the Client, Their Budget, and Cash Flows</div>
      </div>
      <br />
      <div className={Styles.headline}>+ Preserving, Converting and Transferring Wealth for Clients Course</div> */}
      <br /><br />
      <h5 className={Styles.head}>WHAT MAKES THE CIWM® SO UNIQUE?</h5>
      <p className='py-2 fontSize'>The CIWM® training program</p>
      <ul className='fs3'>
        <li>ensures an in-depth knowledge of international wealth 
          management that reflects the cross-border connections and investment of today's wealth owners</li>
        <li>teaches sophisticated techniques to assess a client's overall financial situation and risks</li>
        <li>takes a holistic approach to a customer's financial and personal situation (accounts, tax, cash flow,
           portfolio, real estate, family etc.)</li>
        <li>focuses on the psychological factors influencing the customer and the advisor (behavioural finance)</li>
        <li>considers the relationship between the advisor and the customer (relationship management)</li>
        <li>has a truly international focus while </li>
      </ul>
      <br /><br />
      <h5 className={Styles.head}>PROGRAM OVERVIEW </h5>
      <p className="py-2 fontSize">The course curriculum is split across two modules and delivered through a self-paced eLearning 
      platform. The enrolment period is one year. However, the program can be completed in 15 weeks. The program concludes with a 
      proctored exam for each module. The two exams can be completed at the same exam sitting. </p>
      <br />
      <div className={Styles.headline}>Module 1 : Accumulating Wealth for Clients </div>
      <br />
      <div className={Styles.headline}>Module 2 : Converting & Transferring Wealth and Special Client Focus</div>
      <br /><br />
      <p
          style={{ fontSize: "18px" }}
          className="text-dark px-sm-5 px-0 my-4 fw-bold"
        >
          Examination Specification
        </p>
        <hr className={Styles.hr} />
        <div className={Styles.secondClass}>
        <div className={Styles.secondClass}>
        <p className="pt-2 fontSize">This is a computer based examination with multiple choice questions.</p>
        <p className="pb-2 fontSize">The examination consists of 90 independent multiple choice questions and 9 caselets/case-based questions. The assessment 
        structure is as follows:</p>
        <div className={Styles.container}>
          <span className="d-flex">
          <div className={`${Styles.half} ${Styles.fixHeight}`} style={{borderRight:'none'}}>
          <p className="pt-3 px-3 fontSize">Multiple choice questions</p>
          <p className="pb-3 px-3 fontSize">[90 questions of 1 each marks]</p>
          </div>
        <div className={`${Styles.half} ${Styles.fixHeight}`}>
            <p className="pt-3 px-3 fontSize">90 Marks</p>
          </div>
          </span>
          <span className="d-flex">
            <div className="w-50 p-3" style={{borderLeft:'2px rgb(197, 195, 195) solid'}}>
            <p className="fontSize">9 Case Based questions</p>
            <p className="fontSize">[6 caselets (each case with 5 questions of 1 mark each)]</p>
            <p className="fontSize">[3 caselets (with 5 questions of 2 marks each)]</p>
            </div>
            <div className="w-50 p-3" style={{borderLeft:'2px rgb(197, 195, 195) solid', borderRight:'2px rgb(197, 195, 195) solid'}}>
            <p className="fontSize">6*5*1 = 30 marks</p>
            <p className="fontSize">3*5*2 = 30 marks</p>
            </div>
          </span>
          <span className='d-flex'>
          <div className={Styles.half} style={{marginBottom: '0',borderRight:'none'}}>
          <p className="fontSize p-2 fw-bold" style={{marginBottom: '0px'}}>Total</p>
          </div>
          <div className={Styles.half}>
          <p className="fontSize p-2" style={{marginBottom: '0px'}}>150 marks</p>
          </div>
          </span>
        </div>
        <p className="pt-4 fontSize">
          The examination should be completed in 3 hours. The passing score for the examination is 60 percent
          which is 90 marks out of toal 150 marks. There shall be negative marking of 25
          percent of the marks assigned to a question.
        </p>
        </div>
        </div>
        <br />
        <hr className={Styles.hr} />
        <br /><br />
        <h5 className={Styles.head}>WHO SHOULD STUDY FOR THE CIWM®?</h5>
        <p className="fontSize py-2">The Certified International Wealth Manager CIWM® is a diploma with a strong practical focus.
        It is aimed at financial, legal and other professionals who already work in the wealth 
        management industry and are keen to develop their in-depth knowledge and expertise. CIWM® is tailor-made for:</p>
        <ul className='fs3'>
          <li>Investment advisors</li>
          <li>Financial planners</li>
          <li>Wealth managers</li>
          <li>Asset managers</li>
          <li>Relationship managers, etc.</li>
        </ul>
        <p
          style={{ fontSize: "18px" }}
          className="text-dark text-center pt-5 pb-2"
        >In recent years, representatives from the following companies have participated in the CIWM® programme (Excerpt):</p>
        <hr className={Styles.hr} />
        <div className={Styles.program}>
          <ul className="fs3 px-sm-3 py-1">
            <li>ABN AMRO Bank</li>
            <li>AIG Private Bank</li>
            <li>Bank Julius Baer</li>
            <li>Bank Sarasin</li>
            <li>Bank Vontobel</li>
            <li>Banque Cantonale de Genève</li>
            <li>Banque Cantonale Vaudoise</li>
            <li>Basler Kantonalbank</li>
            <li>Berner Kantonalbank</li>
            <li>BNP Paribas</li>
            <li>BSI</li>
          </ul>
          <ul className="fs3 px-sm-3 py-1">
            <li>Citigroup</li>
            <li>Clariden Leu</li>
            <li>Cornèr Bank</li>
            <li>Crédit Agricole</li>
            <li>Credit Suisse</li>
            <li>Deloitte & Touche Experta</li>
            <li>Deutsche Bank</li>
            <li>Dresdner Bank</li>
            <li>Fortis Banque</li>
            <li>HSBC</li>
            <li>KBL Richelieu</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>LGT Bank</li>
            <li>Liechtensteinische Landesbank</li>
            <li>Lloyds TSB Bank</li>
            <li>Lombard Odier Darier Hentsch</li>
            <li>Merrill Lynch</li>
            <li>Mirabaud</li>
            <li>Natixis Securities</li>
            <li>Neue Zürcher Bank NZB</li>
            <li>Nordea Bank</li>
            <li>Pictet</li>
            <li>Rahn & Bodmer</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>RBS Coutts Bank</li>
            <li>Reuters</li>
            <li>Rothschild Bank</li>
            <li>Swiss Life</li>
            <li>UBS</li>
            <li>Union Bancaire Privée</li>
            <li>Zürcher Kantonalbank</li>
          </ul>
        </div>
      </div>
      <br /><br />
    </div>
  )
}

export default Third
