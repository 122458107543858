import React from 'react'
import Styles from './Style.module.css';
import Content from './Content';

const Fourth = () => {
  const title = 'Certified International';
  const stitle = 'Wealth Manager (CIWM)';
  const content = 'The leading credential for wealth managers'
  return (
    <div className={Styles.page}>
      <Content title={title} stitle={stitle} content={content} />
      <div className={Styles.padding}>
        <div className={Styles.relative}>
          
          {/* <div className={Styles.items}>
          <p className={Styles.box}>Overview</p>
            <p className={Styles.box}>Requirements</p>
            <p className={Styles.box}>Maintance</p>
            <p className={Styles.box}>Benefits</p>
            <p className={Styles.box}>FAQs</p>
            <button className={Styles.view}>Enrol</button>
          </div> */}
          {/* <p className="p-4 p-sm-3"></p> */}
          <p style={{fontSize: '18px'}} className="text-dark my-4 fw-bold">Learning Pathway</p>
          <div className={Styles.boxes}>
            <div className={Styles.info}>Accumulating Wealth For Clients (AWC) Course & Exam</div>
            <hr className={Styles.borderBottom} />
            <div className={Styles.info}>Protecting, Converting and Transferring Wealth (PCT) Course & Exam</div>
            <hr className={Styles.borderBottom} />
            <div className={Styles.backImage}>
              <p>CIWM</p>
            </div>
          </div>
          <p className="p-4 p-sm-1"></p>
          <div className={Styles.space}>
          <p style={{fontSize: '18px'}} className="text-dark fw-bold">Enrol</p>
          <p style={{fontSize: '14px', color: 'blue'}}>+Expand All| - Collapse All</p>
          </div>
          <div className={Styles.headline}>+ Online Course</div>
          <p className="p-1"></p>
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>Accumulating Wealth for Clients</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <p className="p-1"></p>
          <hr className={Styles.hr} />

          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>Protecting, Converting and Transferring Wealth</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <br />
          <div className={Styles.headline}>- Exam Only</div>
          <p className="p-1"></p>
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>AWC Certification Exam</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <p className="p-1"></p>
          <hr className={Styles.hr} />
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>PCT Certification Exam</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <br />
          <div className={Styles.headline}>- Online Course & Exam</div>
          <p className="p-1"></p>
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>Accumulating Wealth for Clients Course and Certification Exam Bundle</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <p className="p-1"></p>
          <hr className={Styles.hr} />
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>Protecting, Converting and Transferring Wealth Course and Certification Exam Bundle</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
          <br />
          <div className={Styles.headline}>- CIWM Full Bundle</div>
          <p className="p-1"></p>
          <hr className={Styles.hr} />
          <div className={Styles.enrol}>
          <p style={{fontSize: '14px'}}>CIWM Full Bundle</p>
          <span className={Styles.enrol}>
          <p className="px-5" style={{fontSize: '14px'}}>$000</p>
          <button className={Styles.fix}>add <span className={Styles.hide}>to cart</span></button>
          </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fourth
