import './App.css';
import { BrowserRouter , Route , Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Second from './components/Second';
import Third from './components/Third';
import Fourth from './components/Fourth';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
         <Route path='/' element={<Home />} />
         <Route path='/second' element={<Second />} />
         <Route path='/third' element={<Third />} />
         <Route path='/fourth' element={<Fourth />} />
       </Routes>
    </BrowserRouter>
  );
}

export default App;
